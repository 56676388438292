import React, { ReactNode, useMemo, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
  hasRelationWarnings,
  EntityRelationWarning,
} from '@backstage/plugin-catalog';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent, isTechDocsAvailable } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';
import BadgeIcon from '@material-ui/icons/CallToAction';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { LightBox, ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { EntityBadgesDialog } from '@backstage-community/plugin-badges';
import {
  isGitlabAvailable,
  EntityGitlabContent,
} from '@immobiliarelabs/backstage-plugin-gitlab';
import { EntitySonarQubeCard } from '@backstage-community/plugin-sonarqube';
import {
  isEndOfLifeAvailable,
  EntityEndOfLifeCard,
} from '@dweber019/backstage-plugin-endoflife';
import { EntitySentryCard, isSentryAvailable } from '@backstage-community/plugin-sentry';
import {
  EntityAdrContent,
  isAdrAvailable,
} from '@backstage-community/plugin-adr';
import { semanticParser } from './changelogParser';
import { EntityChangelogCard } from '@rsc-labs/backstage-changelog-plugin';
import {
  type DevcontainersConfig,
  DevcontainersProvider,
  ExampleDevcontainersComponent,
} from '@coder/backstage-plugin-devcontainers-react';
import { EntityAllureReportContent } from '@backstage-community/plugin-allure';
import { EntityTodoContent } from '@backstage-community/plugin-todo';
import { EntityCicdStatisticsContent } from '@backstage-community/plugin-cicd-statistics';
import { FeatureFlagged } from '@backstage/core-app-api';
import { EntityTipsDialog } from 'backstage-plugin-tips'
import { SoegEasyPermissionTable, SoegKpiPage } from 'backstage-plugin-soeg';
import { EntityOnboardingWorkflow } from './workflows/EntityOnboardingWorkflow/EntityOnboardingWorkflow';
import { WorkflowButtonsPanel } from './WorkflowButtonsPanel';

const customEntityFilterKind = ['Component', 'API', 'System'];

const devcontainersConfig: DevcontainersConfig = {
  tagName: 'devcontainers',
};

const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);

  const extraMenuItems = useMemo(() => {
    return [
      {
        title: 'Badges',
        Icon: BadgeIcon,
        onClick: () => setBadgesDialogOpen(true),
      },
    ];
  }, []);

  return (
    <>
      <EntityLayout
        UNSTABLE_extraContextMenuItems={extraMenuItems}
        UNSTABLE_contextMenuOptions={{
          disableUnregister: 'visible',
        }}
      >
        {props.children}
      </EntityLayout>
      <EntityBadgesDialog
        open={badgesDialogOpen}
        onClose={() => setBadgesDialogOpen(false)}
      />
    </>
  );
};

const techdocsContent = (
  <EntitySwitch>
    <EntitySwitch.Case if={isTechDocsAvailable}>
      <EntityTechdocsContent>
        <TechDocsAddons>
          <ReportIssue />
          <LightBox />
        </TechDocsAddons>
      </EntityTechdocsContent>

    </EntitySwitch.Case>
    <FeatureFlagged with="workflows">
      <EntitySwitch.Case>
        <EntityOnboardingWorkflow
          title="Start using TechDocs"
          description="Showing documentation in this tab requires an annotation to be added to the metadata. 
          This form will onboard your project to use documentation as code by creating a pull request in
          your component's repository. Once you merge that pull request, your docs will show up here.
        "
          templateName="documentation-onboarding"
          namespace="default"
          onError={(error: Error | undefined) => (
            <h2>{error?.message ?? 'Houston we have a problem.'}</h2>
          )}
        />
      </EntitySwitch.Case>
    </FeatureFlagged>
    <FeatureFlagged without="workflows">
      <EntitySwitch.Case>
        <EntityTechdocsContent/>
      </EntitySwitch.Case>
    </FeatureFlagged>
  </EntitySwitch>
);

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={isGitlabAvailable}>
      <EntityGitlabContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
      />
    </EntitySwitch.Case>
  </EntitySwitch>
);

const entityWarningContent = (
  <>
    <EntitySwitch>
      <EntitySwitch.Case if={isOrphan}>
        <Grid item xs={12}>
          <EntityOrphanWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasRelationWarnings}>
        <Grid item xs={12}>
          <EntityRelationWarning />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntitySwitch>
      <EntitySwitch.Case if={hasCatalogProcessingErrors}>
        <Grid item xs={12}>
          <EntityProcessingErrorsPanel />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch>

    <EntityTipsDialog />
  </>
);


// const errorsContent = (
//   <EntitySwitch>
//     <EntitySwitch.Case>
//       <Grid item>
//         <EntityDatadogContent />
//       </Grid>
//     </EntitySwitch.Case>
//   </EntitySwitch>
// );

// ORIGINAL
// const overviewContent = (
//   <Grid container spacing={3} alignItems="stretch">
//     {entityWarningContent}
//     <Grid item md={6}>
//       <EntityAboutCard variant="gridItem" />
//     </Grid>
//     <Grid item md={6} xs={12}>
//       <EntityCatalogGraphCard variant="gridItem" height={400} />
//     </Grid>

//     <Grid item md={4} xs={12}>
//       <EntityLinksCard />
//     </Grid>
//     <Grid item md={8} xs={12}>
//       <EntityHasSubcomponentsCard variant="gridItem" />
//     </Grid>
//   </Grid>
// );


const overviewContent = (
  <Grid container spacing={3} alignItems="baseline">
    {entityWarningContent}
    <Grid item md={12}>
    <FeatureFlagged with="workflows">
        <WorkflowButtonsPanel />
      </FeatureFlagged>
      </Grid>
    <Grid item md={3} xs={12}>
      <EntityAboutCard variant="gridItem" />
    </Grid>

    <Grid item md={4} xs={12}>
      <EntityCatalogGraphCard variant="gridItem" height={400} />
      {/* <EntitySwitch>
        <EntitySwitch.Case if={isOpsgenieAvailable}>
          <EntityOpsgenieAlertsCard title="OpsGenie Alerts" />
        </EntitySwitch.Case>
      </EntitySwitch> */}
    </Grid>
    <FeatureFlagged with="devcontainers">
      <Grid item md={6} xs={12}>
        <DevcontainersProvider config={devcontainersConfig}>
          <ExampleDevcontainersComponent />
        </DevcontainersProvider>
      </Grid>
    </FeatureFlagged>

    <Grid item md={2} xs={12}>
      <EntityLinksCard variant="gridItem" />
    </Grid>

    <Grid item md={3} xs={12}>
      <EntitySonarQubeCard variant="gridItem" />
    </Grid>

    {/* <EntitySwitch>
      <EntitySwitch.Case if={hasLabels}>
        <Grid item md={4} xs={12}>
          <EntityLabelsCard />
        </Grid>
      </EntitySwitch.Case>
    </EntitySwitch> */}

    <Grid item md={12} xs={12}>
      <EntityHasSubcomponentsCard variant="gridItem" />
    </Grid>
    <Grid item md={12}>
      <EntitySwitch>
        <EntitySwitch.Case if={isEndOfLifeAvailable}>
          <EntityEndOfLifeCard />
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>

    <Grid item md={12}>
      <EntitySwitch>
        <EntitySwitch.Case if={isSentryAvailable}>
          <EntitySentryCard />
        </EntitySwitch.Case>
      </EntitySwitch>
    </Grid>
  </Grid>
);

const serviceEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route
      path="/storybook"
      title="Storybook"
      if={isStorybookAvailable}
    >
      <StorybookPage />
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/errors" title="Errors">
      {errorsContent}
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/api" title="API">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/changelog" title="Changelog">
      <EntityChangelogCard parser={semanticParser} />
    </EntityLayout.Route>

    <EntityLayout.Route path="/allure" title="Allure Report">
      <EntityAllureReportContent />
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/tech-insights" title="Scorecards">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <EntityTechInsightsScorecardCard
            title="Scorecard 1"
            description="This is a sample scorecard no. 1"
            checksId={['titleCheck']}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityTechInsightsScorecardCard
            title="Scorecard 2"
            checksId={['techDocsCheck']}
          />
        </Grid>
      </Grid>
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/code-coverage" title="Code Coverage">
      <EntityCodeCoverageContent />
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/todos" title="TODOs">
      <EntityTodoContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd-statistics" title="CI/CD Statistics">
      <EntityCicdStatisticsContent />
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/costs" title="Costs">
      <EntityCostInsightsContent />
    </EntityLayout.Route> */}
  </EntityLayoutWrapper>
);

const websiteEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd" title="CI/CD">
      {cicdContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/errors" title="Errors">
      {errorsContent}
    </EntityLayout.Route> */}

    <EntityLayout.Route path="/dependencies" title="Dependencies">
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    {/* <EntityLayout.Route path="/code-insights" title="Code Insights">
      <EntityGithubInsightsContent />
    </EntityLayout.Route> */}

    {/* <EntityLayout.Route path="/code-coverage" title="Code Coverage">
      <EntityCodeCoverageContent />
    </EntityLayout.Route> */}
    <EntityLayout.Route path="/changelog" title="Changelog">
      <EntityChangelogCard parser={semanticParser} />
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/todos" title="TODOs">
      <EntityTodoContent />
    </EntityLayout.Route>
    <EntityLayout.Route path="/ci-cd-statistics" title="CI/CD Statistics">
      <EntityCicdStatisticsContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

/**
 * NOTE: This page is designed to work on small screens such as mobile devices.
 * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
 * since this does not default. If no breakpoints are used, the items will equitably share the available space.
 * https://material-ui.com/components/grid/#basic-grid.
 */

const defaultEntityPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      {overviewContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/docs" title="Docs">
      {techdocsContent}
    </EntityLayout.Route>

    <EntityLayout.Route path="/changelog" title="Changelog">
      <EntityChangelogCard parser={semanticParser} />
    </EntityLayout.Route>

    <EntityLayout.Route if={isAdrAvailable} path="/adrs" title="ADRs">
      <EntityAdrContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/todos" title="TODOs">
      <EntityTodoContent />
    </EntityLayout.Route>

    <EntityLayout.Route path="/ci-cd-statistics" title="CI/CD Statistics">
      <EntityCicdStatisticsContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const componentPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isComponentType('service')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('component')}>
      {serviceEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case if={isComponentType('website')}>
      {websiteEntityPage}
    </EntitySwitch.Case>

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);

const apiPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item md={6} xs={12}>
          <EntityAboutCard />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <EntityProvidingComponentsCard />
            </Grid>
            <Grid item xs={12} md={6}>
              <EntityConsumingComponentsCard />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/definition" title="Definition">
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <EntityApiDefinitionCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>

    <EntityLayout.Route path="/changelog" title="Changelog">
      <EntityChangelogCard parser={semanticParser} />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const userPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityUserProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard
            variant="gridItem"
            entityFilterKind={customEntityFilterKind}
          />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const groupPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        {entityWarningContent}
        <Grid item xs={12} md={6}>
          <EntityGroupProfileCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard
            variant="gridItem"
            entityFilterKind={customEntityFilterKind}
            relationsType="aggregated"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityLinksCard />
        </Grid>
      </Grid>
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const systemPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={12}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={12}>
          <SoegEasyPermissionTable />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/diagram" title="Diagram">
      <Grid container spacing={2}>
        <Grid item md={6}>
          <EntityCatalogGraphCard
            variant="gridItem"
            direction={Direction.TOP_BOTTOM}
            title="System Diagram"
            height={400}
            relations={[
              RELATION_PART_OF,
              RELATION_HAS_PART,
              RELATION_API_CONSUMED_BY,
              RELATION_API_PROVIDED_BY,
              RELATION_CONSUMES_API,
              RELATION_PROVIDES_API,
              RELATION_DEPENDENCY_OF,
              RELATION_DEPENDS_ON,
            ]}
            unidirectional={false}
          />
        </Grid>
        <Grid item md={6}>
          <EntityCatalogGraphCard variant="fullHeight" height={400} />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/api" title="APIs">
      <EntityHasApisCard variant="fullHeight" />
    </EntityLayout.Route>
    <EntityLayout.Route path="/components" title="Components">
      <EntityHasComponentsCard variant="fullHeight" />
    </EntityLayout.Route>
    <EntityLayout.Route path="/resources" title="Resources">
      <EntityHasResourcesCard variant="gridItem" />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

const domainPage = (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6}>
          <EntityHasSystemsCard variant="gridItem" />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/kpi" title="KPI">
      <SoegKpiPage />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);

export const entityPage = (
  <EntitySwitch>
    <EntitySwitch.Case if={isKind('component')} children={componentPage} />
    <EntitySwitch.Case if={isKind('api')} children={apiPage} />
    <EntitySwitch.Case if={isKind('group')} children={groupPage} />
    <EntitySwitch.Case if={isKind('user')} children={userPage} />
    <EntitySwitch.Case if={isKind('system')} children={systemPage} />
    <EntitySwitch.Case if={isKind('domain')} children={domainPage} />

    <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
  </EntitySwitch>
);
