import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const myappsAdminPlugin = createPlugin({
  id: 'myapps-admin',
  routes: {
    root: rootRouteRef,
  },
});

export const MyappsAdminPage = myappsAdminPlugin.provide(
  createRoutableExtension({
    name: 'MyappsAdminPage',
    component: () =>
      import('./components/MyappsAdminComponent').then(m => m.MyappsAdminComponent),
    mountPoint: rootRouteRef,
  }),
);
