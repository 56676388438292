import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const ecrScanReportPlugin = createPlugin({
  id: 'ecr-scan-report',
  routes: {
    root: rootRouteRef,
  },
});

export const EcrScanReportPage = ecrScanReportPlugin.provide(
  createRoutableExtension({
    name: 'EcrScanReportPage',
    component: () =>
      import('./components/EcrScanReportComponent').then(m => m.EcrComponent),
    mountPoint: rootRouteRef,
  }),
);
