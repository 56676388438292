import {
  configApiRef,
  createPlugin,
  createApiFactory,
  discoveryApiRef,
  createRoutableExtension,
  fetchApiRef,
  identityApiRef,
  createApiRef,
  OAuthApi,
  ProfileInfoApi,
  BackstageIdentityApi,
  SessionApi,
  ApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { prismClient, prismApiRef } from './api';

export const piaAuthApiRef: ApiRef<
  OAuthApi & ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'pia',
});

export const myAwsPlugin = createPlugin({
  id: 'my-aws',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: prismApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
        identityApi: identityApiRef,
        oauthApi: piaAuthApiRef,
        configApi: configApiRef,
      },
      factory: ({ discoveryApi, fetchApi, identityApi, oauthApi }) => {
        // eslint-disable-next-line new-cap
        return new prismClient({
          discoveryApi,
          fetchApi,
          identityApi,
          oauthApi
        });
      },
    }),
  ],
});


export const MyAwsPage = myAwsPlugin.provide(
  createRoutableExtension({
    name: 'MyAwsPage',
    component: () =>
      import('./components/MyAwsComponent').then(m => m.MyAwsComponent),
    mountPoint: rootRouteRef,
  }),
);

export const FastRolePage = myAwsPlugin.provide(
  createRoutableExtension({
    name: 'FastRolePage',
    component: () =>
      import('./components/FastRoleComponent').then(m => m.FastRoleComponent),
    mountPoint: rootRouteRef,
  }),
);