import React from 'react';
import { Navigate, Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import { TechRadarPage } from '@backstage-community/plugin-tech-radar';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FeatureFlagged, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { ExplorePage } from '@backstage-community/plugin-explore';
import { badgesPlugin } from '@backstage-community/plugin-badges';
import { shortcutsPlugin } from '@backstage-community/plugin-shortcuts';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { homePage } from './components/home/HomePage';
import { EntityValidationPage } from '@backstage-community/plugin-entity-validation';
import { TimeSaverPage } from '@tduniec/backstage-plugin-time-saver';
import { Tool, ToolboxPage } from '@drodil/backstage-plugin-toolbox';
import { VisitListener } from '@backstage/plugin-home';
import { AnnouncementsPage } from '@procore-oss/backstage-plugin-announcements';
// Scaffolder Extensions
// import { ScaffolderFieldExtensions } from '@backstage/plugin-scaffolder-react';
// import { ValidateKebabCaseFieldExtension } from './scaffolder/ValidateKebabCase';
// import { TwoColumnLayout, FourColumnLayout } from './scaffolder/ColumnLayout';
import { DevToolsPage } from '@backstage/plugin-devtools';
import { customDevToolsPage } from './components/devtools/CustomDevToolsPage';
import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { catalogUnprocessedEntitiesPlugin } from '@backstage/plugin-catalog-unprocessed-entities';
import { explorePage } from './components/explore/ExplorePage';
// import { NotificationsPage } from '@backstage/plugin-notifications';
import { GraphiQLPage } from '@backstage-community/plugin-graphiql';
import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { piaAuthApiRef } from './apis';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import { MyAwsPage } from 'backstage-plugin-my-aws';
import { EcrScanReportPage } from 'backstage-plugin-ecr-scan-report';
import { MyappsAdminPage } from 'backstage-plugin-myapps-admin';
import { SherlockPage } from 'backstage-plugin-sherlock';
import { SoegLocalAiPage } from 'backstage-plugin-soeg-local-ai';
import { OpsgeniePage } from '@k-phoen/backstage-plugin-opsgenie';
import { SecretsContextProvider } from '@backstage/plugin-scaffolder-react';

// Extra Tools
// const SoegValidator = lazy(() => import('./tools/soegTestTool'));
// const MyAws = lazy(() => import('./tools/myAws'));

// const extraToolExample: Tool = {
//   id: 'soeg-validator',
//   name: 'Soeg Validator',
//   description: 'Validate the word Soeg (example tool)',
//   component: <SoegValidator />,
//   category: 'Soeg',
// };

// const myAws: Tool = {
//   id: 'my-aws',
//   name: 'My Aws',
//   description: 'Easy Role Switcher for AWS',
//   component: <MyAws />,
//   category: 'Soeg',
// };

// const extraTools = [extraToolExample, myAws];
const extraTools:Tool[] = [];
const app = createApp({
  apis,
  plugins: [badgesPlugin, shortcutsPlugin, catalogUnprocessedEntitiesPlugin],
  components: {
    SignInPage: props => {
      const configApi = useApi(configApiRef);
      if (configApi.getString('auth.environment') === 'production') {
        return (
          <SignInPage
            {...props}
            providers={[
              {
                id: 'pia-auth-provider',
                title: 'PIA',
                message: 'Sign in using PIA',
                apiRef: piaAuthApiRef,
              }
            ]}
            title="Select a sign-in method"
            align="center"
          />
        );
      }
      return (
        <SignInPage
          {...props}
          providers={[
            'guest',
            {
              id: 'pia-auth-provider',
              title: 'PIA',
              message: 'Sign in using PIA',
              apiRef: piaAuthApiRef,
            }
          ]}
          title="Select a sign-in method"
          align="center"
        />
      );
    },
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      {homePage}
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route
      path="/opsgenie"
      element={<OpsgeniePage onCallListCardsCount={8} />}
    />
    <Route path="/docs" element={<TechDocsIndexPage />} />
    
    <Route
      path="/create"
      element={
        <ScaffolderPage
          groups={[
            {
              title: 'Foundation',
              filter: entity =>
                entity?.metadata?.tags?.includes('foundation') ?? false,
            },
            {
              title: 'Uniframe',
              filter: entity =>
                entity?.metadata?.tags?.includes('uniframe') ?? false,
            },
            {
              title: 'Joyn Web',
              filter: entity =>
                entity?.metadata?.tags?.includes('joyn-web') ?? false,
            },
            {
              title: 'Backstage',
              filter: entity =>
                entity?.metadata?.tags?.includes('backstage') ?? false,
            },
          ]}
        />
      }
    >
      {/* <ScaffolderLayouts>
        <TwoColumnLayout />
        <FourColumnLayout />
      </ScaffolderLayouts>
      <ScaffolderFieldExtensions>
        <ValidateKebabCaseFieldExtension />
      </ScaffolderFieldExtensions> */}
    </Route>
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/tech-radar"
      element={<TechRadarPage width={1500} height={800} />}
    />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/explore" element={<ExplorePage />}>
      {explorePage}
    </Route> 
    {/* // discovery.p7s1.io compatibility */}
    <Route path="/aws" element={<Navigate to="/explore/myaws" />} />
    <Route path="/jumphost" element={<Navigate to="/explore/myaws" />} />
    <Route path="/myapps" element={<Navigate to="/explore/tools" />} />
    {/* Check ecr-scan integration automation */}
    <Route path="/ecr-scan-report" element={<EcrScanReportPage />} />
    <Route path="/entity-validation" element={<EntityValidationPage />} />
    <Route path="/time-saver" element={<TimeSaverPage />} />
    <Route path="/sherlock" element={<SherlockPage />} /> 
    {/* Check sherlock integration automation */}
    <Route path="/toolbox" element={<ToolboxPage extraTools={extraTools} />} />
    <Route path="/announcements" element={<AnnouncementsPage />} />
    <Route
      path="/devtools"
      element={
        <RequirePermission permission={devToolsAdministerPermission}>
          <DevToolsPage />
        </RequirePermission>
      }
    >
      {customDevToolsPage}
    </Route>
    {/* <Route path="/notifications" element={<NotificationsPage />} /> */}
    <FeatureFlagged with="local-ai">
      <Route path="/soeg-local-ai" element={<SoegLocalAiPage />} />
    </FeatureFlagged>
    <Route path="/myapps-admin" element={<MyappsAdminPage />} /> 
    <Route path="/graphiql" element={<GraphiQLPage />} />
    <Route path="/my-aws" element={<MyAwsPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <SecretsContextProvider>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </SecretsContextProvider>,
);
