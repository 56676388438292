import ApiIcon from '@material-ui/icons/Extension';
import DomainIcon from '@material-ui/icons/Apartment';
import ResourceIcon from '@material-ui/icons/Work';
import SystemIcon from '@material-ui/icons/Category';
import DocsIcon from '@material-ui/icons/Directions';
import TechRadarIcon from '@material-ui/icons/Fastfood';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
import CardTravel from '@material-ui/icons/CardTravel';
import {
  HomePageRandomJoke,
  ComponentTabs,
  WelcomeTitle,
  HeaderWorldClock,
  ClockConfig,
  HomePageStarredEntities,
  HomePageToolkit,
  HomePageRecentlyVisited,
  ComponentTab,
} from '@backstage/plugin-home';
import { Content, Header, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
// import { MicrosoftCalendarCard } from '@backstage/plugin-microsoft-calendar';
import Grid from '@material-ui/core/Grid';
import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Chip,
  Typography,
} from '@material-ui/core';
import {
  AnnouncementsCard,
  NewAnnouncementBanner,
} from '@procore-oss/backstage-plugin-announcements';

const clockConfigs: ClockConfig[] = [
  {
    label: 'UTC',
    timeZone: 'UTC',
  },
  {
    label: 'Berlin',
    timeZone: 'Europe/Berlin',
  },
  {
    label: 'Porto',
    timeZone: 'Europe/Lisbon',
  },
];

const links = [
  {
    url: 'catalog?filters[kind]=domain',
    label: 'Domains',
    icon: <DomainIcon />,
  },
  {
    url: 'catalog?filters[kind]=system',
    label: 'Systems',
    icon: <SystemIcon />,
  },
  {
    url: 'catalog?filters[kind]=api',
    label: 'APIs',
    icon: <ApiIcon />,
  },
  {
    url: 'catalog?filters[kind]=resource&filters[type]=lambda',
    label: 'Lambdas',
    icon: <ResourceIcon />,
  },
];
const WidgetsRenderer = (props: any) => <ComponentTab expanded {...props} />;

const timeFormat: Intl.DateTimeFormatOptions = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false,
};

const RoundedBox = (props: any) => {
  return (
    <Card style={{ background: props.color }}>
      {' '}
      {/* Adjust borderRadius as needed */}
      <CardHeader
        title={props.title || 'Title'}
        titleTypographyProps={{ align: 'center', variant: 'h6' }}
        sx={{ backgroundColor: '#f0f0f0' }} // Optional: style for the header background
      />
      <CardContent>
        <Typography variant="body2">
          {props.description || 'Description'}
          {props.children}
        </Typography>
      </CardContent>
    </Card>
  );
};

const InfoBox = () => {
  return (
    <Grid container spacing={2} justifyContent="center" alignItems="baseline">
      <Chip label="New" />
      <Typography variant="caption">
        New to Backstage? Use{' '}
        <a href="/docs/default/system/backstage" style={{ color: 'blue' }}>
          these guides
        </a>{' '}
        to get started
      </Typography>
    </Grid>
  );
};

export const homePage = (
  <Page themeId="home">
    <Header title={<WelcomeTitle />} pageTitleOverride="Home">
      <Grid container >
        <HeaderWorldClock
          clockConfigs={clockConfigs}
          customTimeFormat={timeFormat}
        />
      </Grid>
      <Grid container>
        <HomePageSearchBar style={{ borderRadius: '10px' }} />
      </Grid>
    </Header>
    <Content>
      <Grid container spacing={3}>
        <NewAnnouncementBanner />
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <InfoBox />
        </Grid>
        <Grid item md={4}>
          <HomePageRecentlyVisited />
        </Grid>
        <Grid item md={4}>
          <HomePageStarredEntities />
        </Grid>
        <Grid item md={4}>
          <AnnouncementsCard />
        </Grid>
        <Grid item md={4}>
          <RoundedBox
            title="📚 Entity Catalog"
            description="Explore SOEG Entities and discover solutions available in your ecosystem."
            color="#00bf9b"
            children={
              <HomePageToolkit
                Renderer={WidgetsRenderer}
                title=" "
                tools={links}
              />
            }
          />
        </Grid>
        <Grid item md={4}>
          <RoundedBox
            title="🗂️ Documentation"
            description="Find documentation available at Soeg and get reading"
            color="#aaaaaa"
            children={
              <HomePageToolkit
                Renderer={WidgetsRenderer}
                title=" "
                tools={[
                  {
                    url: '/tech-radar',
                    label: 'Tech-Radar',
                    icon: <TechRadarIcon />,
                  },
                  {
                    url: '/docs?filters%5Buser%5D=all',
                    label: 'Techdocs',
                    icon: <DocsIcon />,
                  },
                ]}
              />
            }
          />
        </Grid>
        <Grid item md={4}>
          <RoundedBox
            title="🧑‍💻 Create"
            description="Create new software components using standard templates"
            color="rgba(224, 4, 51, 0.87)"
            children={
              <HomePageToolkit
                Renderer={WidgetsRenderer}
                title=" "
                tools={[
                  {
                    url: '/create',
                    label: 'Software-Templates',
                    icon: <CreateComponentIcon />,
                  },
                  {
                    url: '/toolbox',
                    label: 'Toolbox',
                    icon: <CardTravel />,
                  },
                ]}
              />
            }
          />
        </Grid>
        <Grid item md={12}>
          <ComponentTabs
            title="Random Jokes"
            tabs={[
              {
                label: 'Programming',
                Component: () => (
                  <HomePageRandomJoke defaultCategory="programming" />
                ),
              },
              {
                label: 'Any',
                Component: () => <HomePageRandomJoke defaultCategory="any" />,
              },
            ]}
          />
        </Grid>
      </Grid>
    </Content>
  </Page>
);
