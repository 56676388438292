import { Button, Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { MouseEvent, useCallback, useState } from 'react';
import { UpdateSystem } from './workflows/UpdateSystem/UpdateSystem';
import { DeprecateComponent } from './workflows/DeprecateComponent/DeprecateComponent'
import { useEntity } from '@backstage/plugin-catalog-react';
import { IdleComponentType, ModalComponentProps, ModalTaskProgress, SuccessComponentProps, WorkflowButton } from '@frontside/backstage-plugin-scaffolder-workflow';
import { IconComponent, useApp } from '@backstage/core-plugin-api';
import { Link } from '@backstage/core-components';
import { ANNOTATION_ORIGIN_LOCATION, stringifyEntityRef } from '@backstage/catalog-model';
import WebIcon from '@material-ui/icons/Web';
import { JsonValue } from '@backstage/types';

const useStyles = makeStyles(theme => ({
  label: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    fontSize: '10px',
    fontWeight: 'bold',
    letterSpacing: 0.5,
    overflow: 'hidden',
    whiteSpace: 'nowrap',
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  idle: {
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
  },
  pending: {
    backgroundColor: theme.palette.warning.main,
    color: '#ffffff',
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color: '#ffffff',
  },
  success: {
    backgroundColor: theme.palette.success.main,
    color: '#ffffff',
  },
}));

const defaultWorkflows = [
  {
    "title": "deprecate",
    "component": <DeprecateComponent />
  },
  {
    "title": "Update System",
    "component": <UpdateSystem />
  },

]

const Idle: IdleComponentType<{
  initialState: Record<string, JsonValue>;
}> = ({ execute, initialState }) => {
  const classes = useStyles();

  const clickHandler = useCallback(
    (e: MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      // TODO: how do I make this type so it doesn't need to be optional?
      // @ts-expect-error ts(2722)
      execute(initialState);
    },
    [execute, initialState],
  );

  return (
    <Button
      variant="contained"
      color="primary"
      disableRipple
      disableFocusRipple
      type="button"
      size="medium"
      className={classes.idle}
      onClick={clickHandler}
    >
      Deprecate
    </Button>
  );
};

const Pending = () => {
  const classes = useStyles();
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableRipple
        disableFocusRipple
        type="button"
        size="medium"
        className={classes.pending}
      >
        Running
      </Button>
    </>
  );
};

const Error = () => {
  const classes = useStyles();

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        disableRipple
        disableFocusRipple
        type="button"
        size="medium"
        className={classes.error}
      >
        Failed
      </Button>
    </>
  );
};

const Success = ({ taskStream }: SuccessComponentProps) => {
  const classes = useStyles();
  const app = useApp();
  const iconResolver = (key?: string): IconComponent =>
    app.getSystemIcon(key!) ?? WebIcon;

  return (
    <>
      {taskStream?.output?.links &&
        taskStream.output.links.map(({ url, title, icon }, i) => {
          const Icon = iconResolver(icon);

          return (
            <Link to={url ?? ''} key={i} className={classes.link}>
              <Button
                variant="contained"
                type="button"
                color="primary"
                disableRipple
                disableFocusRipple
                size="medium"
                startIcon={<Icon />}
              >
                {title}
              </Button>
            </Link>
          );
        })}
    </>
  );
};

const Modal = ({ taskStream, taskStatus }: ModalComponentProps) => {
  const [open, setOpen] = useState(false);
  const closeHandler = useCallback(() => setOpen(false), []);

  if (taskStatus !== 'idle' && taskStream) {
    return (
      <>
        <Button
          color="secondary"
          disableRipple
          disableFocusRipple
          onClick={() => setOpen(true)}>
          Show Logs
        </Button>
        <ModalTaskProgress
          taskStream={taskStream}
          open={open}
          onClick={closeHandler}
          onClose={closeHandler}
        />
      </>
    );
  }

  return null;
};

export function WorkflowButtonsPanel(): JSX.Element {
  const classes = useStyles();
  const { entity } = useEntity();
  const entityRef = stringifyEntityRef(entity);

  const url = entity.metadata?.annotations?.[
    ANNOTATION_ORIGIN_LOCATION
  ].replace(/^url:/, '') ?? 'error';
  try {
    const entityWorkflows = entity?.metadata?.annotations?.workflows ? JSON.parse(entity?.metadata?.annotations?.workflows) : []
    return (
      <Card>
        <CardContent>
          <Typography variant='h5' gutterBottom>
            Workflows
          </Typography>
          <Grid container spacing={3}>
            {defaultWorkflows.map((workflow, index) => (
              <Grid item key={index}>
                <Typography variant='body2' className={classes.label}>
                  {workflow.title}
                </Typography>
                {workflow.component}
              </Grid>
            ))}
            {entityWorkflows.map((workflow: { title: string; workflow: string; }, index: React.Key | null | undefined) => (
              <Grid item key={index}>
                <Typography variant='body2' className={classes.label}>
                  {workflow.title}
                </Typography>
                <WorkflowButton
                  namespace="default"
                  templateName={workflow.workflow}
                  components={{
                    idle: <Idle initialState={{ url, entityRef }} />,
                    pending: <Pending />,
                    error: <Error />,
                    success: <Success />,
                    modal: <Modal />,
                  }}
                />
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>
    );
  } catch (error) {
    return (
      <Card>
        <CardContent>
          <Typography variant='h5' gutterBottom>
            Workflows
          </Typography>
          <Grid container spacing={3}>
            {defaultWorkflows.map((workflow, index) => (
              <Grid item key={index}>
                <Typography variant='body2' className={classes.label}>
                  {workflow.title}
                </Typography>
                {workflow.component}
              </Grid>
            ))}
              <Grid item>
                <Typography variant='body2' className={classes.label}>
                  Wrong JSON
                </Typography>
                Unable to format workflow annotation
              </Grid>
          </Grid>
        </CardContent>
      </Card>
    );
  }

}

