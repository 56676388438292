import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
  IdentityApi,
  OAuthApi,
} from '@backstage/core-plugin-api';

export const prismApiRef = createApiRef<prismApi>({
  id: 'plugin.myaws.service',
});

interface Role {
  name: string;
  accountid: string; // Assuming `account_id` should be named as per standard naming conventions.
  aws_arn: string;
  link: string;
}

export interface prismApi {
  getAccounts(): Promise<any>;
  getAccessToken(): Promise<any>;
  getAwsConfig(): Promise<any>;
  getRoles(): Promise<Role[]>;
  getPiaRoles(): Promise<any>;
  getJumphosts(): Promise<any>;
  getEcrScanReport(pid: string, id: string): Promise<any>;
  getSherlockReport(pipipelineid: string): Promise<any>;
  getSherlockScan(pipelineid: string, scanid: string): Promise<any>;
  getSherlockScanResult(url: string): Promise<any>;
  prismFetchItems: () => Promise<any[]>;
  prismEditItem: (itemId: string, newData: any) => Promise<void>;
  prismDeleteItem: (itemId: string) => Promise<void>;
  prismUploadImage: (itemId: string, imageData: any) => Promise<void>;
  prismAddItem: (newToolData: any) => Promise<void>;
  fetchPIATools(): Promise<any[]>;
}

export class prismClient implements prismApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;
  private readonly identityApi: IdentityApi;
  private readonly oauthApi: OAuthApi;

  constructor(options: { discoveryApi: DiscoveryApi; fetchApi: FetchApi, identityApi: IdentityApi, oauthApi: OAuthApi }) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
    this.identityApi = options.identityApi;
    this.oauthApi = options.oauthApi;
  }

  async getAccessToken(): Promise<any> {
    return await this.oauthApi.getAccessToken()
  }

  async getUserId(): Promise<string> {
    return (await this.identityApi.getBackstageIdentity()).userEntityRef.replaceAll("user:default/", "");
  }

  async getAccounts(): Promise<any> {
    const token = await this.getAccessToken();
    const userid = await this.getUserId();

    return await this.fetchApi
      .fetch(
        `${await this.discoveryApi.getBaseUrl(
          'proxy',
        )}/api-sre/my/${userid}/aws/profiles`,
        {
          mode: 'cors',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(resp => resp.json());
  }

  async getMyJumphosts(): Promise<any> {
    const token = await this.getAccessToken();
    const userid = await this.getUserId();

    return await this.fetchApi
      .fetch(
        `${await this.discoveryApi.getBaseUrl(
          'proxy',
        )}/api-sre/my/${userid}/jumphosts`,
        {
          mode: 'cors',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(resp => resp.json());
  }

  async getPiaRoles(): Promise<any> {
    const token = await this.getAccessToken();
    const userid = await this.getUserId();

    return await this.fetchApi
      .fetch(
        `${await this.discoveryApi.getBaseUrl(
          'proxy',
        )}/api-sre/my/${userid}/pia/roles`,
        {
          mode: 'cors',
          headers: {
            'x-requested-with': 'XMLHttpRequest',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(resp => resp.json());
  }

  

  async getAwsConfig(): Promise<any> {
    const accounts = await this.getAccounts();
    const userid = await this.getUserId();

    const awsconf_template = [
      '### START autogenerated by https://backstage.p7s1.io ###',
      '',
      '# HOWTO https://sre.p7s1.io/AWS/login.html',
      '# EXAMPLE',
      '',
      '# file: .aws/credentials',
      '# [t1p-iam-central]',
      '# aws_access_key_id = A****',
      '# aws_secret_access_key = *****',
      `# mfa_serial = arn:aws:iam::912477954803:mfa/${userid}`,
      '',
      '# file: .aws/config',
      '',
    ];

    // JSON for deep copy
    const awsconf = JSON.parse(JSON.stringify(awsconf_template));
    accounts?.map((account: { profile_name: any; aws_arn: any }) => {
      awsconf.push(`[profile ${account.profile_name}]`);
      awsconf.push(`role_arn = ${account.aws_arn}`);
      awsconf.push('source_profile = t1p-iam-central');
      // We use aws-sts to simplify login don't do this
      // awsconf.push(
      //   `mfa_serial = arn:aws:iam::912477954803:mfa/${username.access_token_decoded.preferred_username}`,
      // );
      awsconf.push('');
    });
    awsconf.push('### END autogenerated by https://backstage.p7s1.io ###');

    const cli = awsconf.join('\n');

    const awsconf_web_template = [
      '### START autogenerated by https://backstage.p7s1.io ###',
      '',
      '# Config for AWS Extend Switch Roles - (chrome browser extension)',
      '# https://chrome.google.com/webstore/detail/aws-extend-switch-roles/jpmkfafbacpgapdghgdpembnojdlgkdl',
      '',
    ];

    const awswebconf = JSON.parse(JSON.stringify(awsconf_web_template));
    accounts?.map((account: { profile_name: any; aws_arn: any }) => {
      awswebconf.push(`[profile ${account.profile_name}]`);
      awswebconf.push(`role_arn = ${account.aws_arn}`);
      if (account.profile_name.startsWith('t1p')) {
        awswebconf.push('color = de4545');
      }
      awswebconf.push('');
    });
    awswebconf.push('### END autogenerated by https://backstage.p7s1.io ###');

    const web = awswebconf.join('\n');

    return { cli: cli, web: web };
  }

  async getRoles(): Promise<Role[]> {
    const accounts = await this.getAccounts();
    return accounts.map(
      (account: {
        account_name: any;
        account_id: any;
        aws_arn: any;
        switch_url: any;
      }) => {
        return {
          name: account.account_name,
          accountid: account.account_id,
          aws_arn: account.aws_arn,
          link: account.switch_url,
        };
      },
    );
  }

  async getJumphosts(): Promise<any> {
    const jumphosts = await this.getMyJumphosts();
    return jumphosts.map((jh: { name: string; fingerprint: string }) => {
      return {
        name: jh.name,
        fingerprint: jh.fingerprint,
      };
    });
  }

  async getEcrScanReport(pid: string, id: string): Promise<any> {
    const token = await this.getAccessToken();

    return await this.fetchApi
      .fetch(
        `${await this.discoveryApi.getBaseUrl(
          'proxy',
        )}/api-sre/ecr-reports/${pid}/${id}`,
        {
          mode: 'cors',
          headers: {
            'x-requested-with': 'XMLHttpRequest',
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(resp =>
        resp.json().catch(err => {
          // eslint-disable-next-line no-console
          console.error(`Failed to get scan report${err}`);
          return {};
        }),
      );
  }

  async getSherlockReport(pipelineid: string): Promise<any> {
    const token = await this.getAccessToken();

    return await this.fetchApi
      .fetch(
        `${await this.discoveryApi.getBaseUrl('proxy/sherlock')}/${pipelineid}`,
        {
          mode: 'cors',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(resp =>
        resp.json().catch(err => {
          // eslint-disable-next-line no-console
          console.error(`Failed to get sherlock report${err}`);
          return {};
        }),
      );
  }

  async getSherlockScan(pipelineid: string, scanid: string): Promise<any> {
    const token = await this.getAccessToken();

    return await this.fetchApi
      .fetch(
        `${await this.discoveryApi.getBaseUrl(
          'proxy/sherlock',
        )}/${pipelineid}/${scanid}`,
        {
          mode: 'cors',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      .then(resp =>
        resp.json().catch(err => {
          // eslint-disable-next-line no-console
          console.error(`Failed to get sherlock report${err}`);
          return {};
        }),
      );
  }

  async getSherlockScanResult(url: string): Promise<any> {
    return await this.fetchApi
      .fetch(url, {
        mode: 'cors',
      })
      .then(resp =>
        resp.json().catch(err => {
          // eslint-disable-next-line no-console
          console.error(
            `Failed to get sherlock scan results from ${url}: ${err}`,
          );
          return {};
        }),
      );
  }
  async prismFetchItems(): Promise<any[]> {
    const token = await this.getAccessToken();

    return await this.fetchApi
      .fetch(`${await this.discoveryApi.getBaseUrl('proxy')}/api-sre/myapps`, {
        mode: 'cors',
        headers: {
          'x-requested-with': 'XMLHttpRequest',
          'Authorization': `Bearer ${token}`
        }
      })
      .then(resp => resp.json());
  }

  async prismDeleteItem(itemId: string): Promise<void> {
    const token = await this.getAccessToken();
    await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('proxy')}/api-sre/myapps/${itemId}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token.access_token}`
      }
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
  }

  async prismAddItem(newToolData: any): Promise<void> {
    const token = await this.getAccessToken();
    try {
      await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('proxy')}/api-sre/myapps`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify(newToolData),
      }).then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error adding new tool:', error);
      throw error;
    }
  }

  async prismEditItem(itemId: string, newData: any): Promise<void> {
    const token = await this.getAccessToken();
    await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('proxy')}/api-sre/myapps/${itemId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(newData),
    }).then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
  }

  async prismUploadImage(itemId: string, imageData: any): Promise<void> {
    const token = await this.getAccessToken();
    try {
      await this.fetchApi.fetch(`${await this.discoveryApi.getBaseUrl('proxy')}/api-sre/myapps/${itemId}/image`, {
        method: 'POST',
        body: imageData,
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }).then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        // eslint-disable-next-line no-console
        console.log('Image uploaded:', response);
        return response.json();
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error uploading image:', error);
      throw error;
    }
  }

  async fetchPIATools(): Promise<any[]> {
    try {
      const token = await this.getAccessToken();
      const userid = await this.getUserId();

      // here we should do the filtering based on the pia permission as described here https://gitlab.p7s1.io/sre/discovery/lambda/-/blob/master/src/app.py
      const piaToolsRequest = await this.fetchApi.fetch(
        `${await this.discoveryApi.getBaseUrl('proxy')}/api-sre/my/${userid}/myapps`,
        {
          mode: 'cors',
          // credentials: 'include',
          headers: {
            'x-requested-with': 'XMLHttpRequest',
            Authorization: `Bearer ${token}`,
          },
        },
      );
      return await piaToolsRequest.json();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return [];
    }
  };
}
