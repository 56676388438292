import React, { PropsWithChildren } from 'react';
import { makeStyles } from '@material-ui/core';

import { devToolsAdministerPermission } from '@backstage/plugin-devtools-common';
import { RequirePermission } from '@backstage/plugin-permission-react';

import HomeIcon from '@material-ui/icons/Home';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import MapIcon from '@material-ui/icons/MyLocation';
import LibraryBooks from '@material-ui/icons/LibraryBooks';
import CreateComponentIcon from '@material-ui/icons/AddCircleOutline';
// import CloudIcon from '@material-ui/icons/Cloud';
import LayersIcon from '@material-ui/icons/Layers';
import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';
import ApiIcon from '@material-ui/icons/Extension';
import ComponentIcon from '@material-ui/icons/Memory';
import DomainIcon from '@material-ui/icons/Apartment';
import ResourceIcon from '@material-ui/icons/Work';
import SystemIcon from '@material-ui/icons/Category';
// import StorefrontIcon from '@material-ui/icons/Storefront';
import CategoryIcon from '@material-ui/icons/Category';
import CardTravel from '@material-ui/icons/CardTravel';
import BuildIcon from '@material-ui/icons/Build';
import PhotoFilter from '@material-ui/icons/PhotoFilter';
import DataUsage from '@material-ui/icons/DataUsage';
// import { NotificationsSidebarItem } from '@backstage/plugin-notifications';

import {
  Settings as SidebarSettings,
  UserSettingsSignInAvatar,
} from '@backstage/plugin-user-settings';
import { SidebarSearchModal } from '@backstage/plugin-search';
import {
  Sidebar,
  sidebarConfig,
  SidebarDivider,
  SidebarGroup,
  SidebarItem,
  SidebarPage,
  SidebarScrollWrapper,
  useSidebarOpenState,
  Link,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import { Shortcuts } from '@backstage-community/plugin-shortcuts';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import { FeatureFlagged } from '@backstage/core-app-api';

const useSidebarLogoStyles = makeStyles({
  root: {
    width: sidebarConfig.drawerWidthClosed,
    height: 3 * sidebarConfig.logoHeight,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    marginBottom: -14,
  },
  link: {
    width: sidebarConfig.drawerWidthClosed,
    marginLeft: 24,
  },
});

const SidebarLogo = () => {
  const classes = useSidebarLogoStyles();
  const { isOpen } = useSidebarOpenState();

  return (
    <div className={classes.root}>
      <Link to="/" underline="none" className={classes.link} aria-label="Home">
        {isOpen ? <LogoFull /> : <LogoIcon />}
      </Link>
    </div>
  );
};

export const Root = ({ children }: PropsWithChildren<{}>) => (
  <SidebarPage>
    <Sidebar>
      <SidebarLogo />
      <SidebarGroup label="Search" icon={<SearchIcon />} to="/search">
        <SidebarSearchModal />
      </SidebarGroup>
      <SidebarDivider />
      <SidebarGroup label="Menu" icon={<MenuIcon />}>
        {/* Global nav, not org-specific */}
        <SidebarItem icon={HomeIcon} to="/" text="Home" />
        <SidebarItem icon={CategoryIcon} to="catalog" text="Catalog">
          <SidebarSubmenu title="Entity Catalog">
            <SidebarSubmenuItem
              title="Domains"
              to="catalog?filters[kind]=domain"
              icon={DomainIcon}
            />
            <SidebarSubmenuItem
              title="Systems"
              to="catalog?filters[kind]=system"
              icon={SystemIcon}
            />
            <SidebarSubmenuItem
              title="Components"
              to="catalog?filters[kind]=component"
              icon={ComponentIcon}
            />
            <SidebarSubmenuItem
              title="APIs"
              to="catalog?filters[kind]=api"
              icon={ApiIcon}
            />
            <SidebarDivider />
            <SidebarSubmenuItem
              title="Resources"
              to="catalog?filters[kind]=resource"
              icon={ResourceIcon}
            />
            <SidebarDivider />
            {/* <SidebarSubmenuItem
              icon={Timelapse}
              to="time-saver"
              title="timeSaver"
            /> */}
          </SidebarSubmenu>
        </SidebarItem>
        <SidebarItem icon={LibraryBooks} to="docs" text="Docs" />
        <SidebarItem icon={ReportProblemIcon} to="opsgenie" text="Incidents" />
        <SidebarItem icon={CreateComponentIcon} to="create" text="Create..." />
        {/* End global nav */}
        <SidebarDivider />
        <SidebarItem icon={DataUsage} to="graphiql" text="GraphQL" />

        <SidebarDivider />

        <SidebarScrollWrapper>
          <SidebarItem icon={MapIcon} to="tech-radar" text="Tech Radar" />
          <SidebarItem
            icon={LayersIcon}
            to="/explore"
            text="Explore"
          />
          <SidebarItem icon={CardTravel} to="toolbox" text="ToolBox" />
        </SidebarScrollWrapper>
      </SidebarGroup>
      <SidebarDivider />
      <FeatureFlagged with="local-ai">
        <SidebarItem icon={PhotoFilter} to="soeg-local-ai" text="Ask Me..." />
      </FeatureFlagged>
      <Shortcuts />
      <SidebarDivider />
      <SidebarGroup
        label="Settings"
        icon={<UserSettingsSignInAvatar />}
        to="/settings"
      >
        <SidebarSettings />
        <RequirePermission
          permission={devToolsAdministerPermission}
          errorPage={<></>}
        >
          <SidebarItem icon={BuildIcon} to="devtools" text="DevTools" />
        </RequirePermission>
        {/* <NotificationsSidebarItem /> */}
      </SidebarGroup>
    </Sidebar>
    {children}
  </SidebarPage>
);
