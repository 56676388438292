import {
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

export const soegLocalAiPlugin = createPlugin({
  id: 'soeg-local-ai',
  routes: {
    root: rootRouteRef,
  },
});

export const SoegLocalAiPage = soegLocalAiPlugin.provide(
  createRoutableExtension({
    name: 'SoegLocalAiPage',
    component: () =>
      import('./components/LocalAIPage').then(m => m.LocalAIPage),
    mountPoint: rootRouteRef,
  }),
);
