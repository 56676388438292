import { ChangelogProps } from '@rsc-labs/backstage-changelog-plugin';
import {
  StatusError,
  StatusOK,
  StatusPending,
  StatusRunning,
} from '@backstage/core-components';

const iconMapping: { [key: string]: React.ReactNode } = {
  Breaking: StatusError.name,
  Features: StatusRunning.name,
  'Bug fixes': StatusOK.name,
  'Misc changes': StatusPending.name,
  // Add more mappings as needed
};

export function semanticParser(changeLogContent: string): ChangelogProps[] {
  const splittedLines = changeLogContent.split(/\r?\n/);

  let changelogIndex = -1;
  let action: string | undefined;
  const ActionIcon: React.ReactNode = StatusOK.name; // Placeholder, replace with actual icon component
  let actionContent: string = '';
  let versionContent: string = '';
  let actionCounter: number = 0;

  const changelogParsed = splittedLines.reduce(
    (resultArray: ChangelogProps[], item: string, currentIndex: number) => {
      const isItLastLine = currentIndex === splittedLines.length - 1;
      if (item.startsWith('## ') || isItLastLine) {
        if (action) {
          resultArray[changelogIndex]?.actions?.push({
            name: action,
            content: `${actionContent.trim()}`,
            counter: actionCounter,
            icon: ActionIcon,
          });
        }

        if (changelogIndex >= 0) {
          resultArray[changelogIndex].versionContent = versionContent.trim();
        }

        action = undefined;
        actionCounter = 0;
        actionContent = '';
        versionContent = '';

        changelogIndex++;

        if (!isItLastLine) {
          resultArray.push({
            versionNumber: item.replace('## ', ''),
            actions: [],
            versionContent: '',
          });
        }
      } else if (item.startsWith('### ')) {
        if (action && actionContent) {
          // skip actions without version
          resultArray[changelogIndex]?.actions?.push({
            name: action,
            content: `${actionContent.trim()}`,
            counter: actionCounter,
            icon: ActionIcon,
          });
        }

        action = item.slice(4);
        actionContent = '';
        actionCounter = 0;
      } else if (item.startsWith('* ')) {
        actionContent += `- ${item.slice(1).trim()}\n`; // Capture the whole line after "*"
        actionCounter++;
      }

      if (item.trim() !== '') {
        versionContent += `${item}\n`;
      }

      return resultArray;
    },
    [],
  );

  // Ensure any final action content is captured
  if (action && actionContent) {
    changelogParsed[changelogIndex]?.actions?.push({
      name: action,
      content: actionContent.trim(),
      counter: actionCounter,
      icon: ActionIcon,
    });
  }

  // After populating the changelogParsed array, iterate over it to assign icons based on action names
  changelogParsed.forEach(version => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    version.actions.forEach(action => {
      action.icon = iconMapping[action.name] || StatusOK; // Fallback to a default icon if action name is not in the mapping
    });
  });

  return changelogParsed;
}
