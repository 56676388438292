import {
  ScmIntegrationsApi,
  scmIntegrationsApiRef,
  ScmAuth,
  scmAuthApiRef,
} from '@backstage/integration-react';
import {
  AnyApiFactory,
  ApiRef,
  BackstageIdentityApi,
  configApiRef,
  createApiFactory,
  createApiRef,
  discoveryApiRef,
  fetchApiRef,
  gitlabAuthApiRef,
  OAuthApi,
  oauthRequestApiRef,
  ProfileInfoApi,
  SessionApi,
} from '@backstage/core-plugin-api';
import { OAuth2 } from '@backstage/core-app-api';
import {
  apiDocsConfigRef,
  defaultDefinitionWidgets,
} from '@backstage/plugin-api-docs';
import { ApiEntity } from '@backstage/catalog-model';
import { P7S1TechRadar } from './techradar';
import { techRadarApiRef } from '@backstage-community/plugin-tech-radar';
import { graphQlBrowseApiRef, GraphQLEndpoints } from '@backstage-community/plugin-graphiql';
import { extraTips, systemModelTips, tipsConfigRef } from 'backstage-plugin-tips';
import { cicdStatisticsApiRef } from '@backstage-community/plugin-cicd-statistics';
import { CicdStatisticsApiGitlab } from '@backstage-community/plugin-cicd-statistics-module-gitlab';
import { exploreApiRef } from '@backstage-community/plugin-explore';

export const piaAuthApiRef: ApiRef<
  OAuthApi & ProfileInfoApi & BackstageIdentityApi & SessionApi
> = createApiRef({
  id: 'pia',
});

export const apis: AnyApiFactory[] = [
  // Customise API Factory
  createApiFactory({
    api: apiDocsConfigRef,
    deps: {},
    factory: () => {
      // load the default widgets
      const definitionWidgets = defaultDefinitionWidgets();
      const appSyncPattern = /@aws\S*[\s{]/g;
      // add the grpc-docs api widget to the definition widgets
      // definitionWidgets.push(grpcDocsApiWidget);
      return {
        getApiDefinitionWidget: (apiEntity: ApiEntity) => {
          if (apiEntity.spec.type === 'graphql') {
            // Patch for @Aws App Sync
            apiEntity.spec.definition = apiEntity.spec.definition.includes("@aws_iam") ? apiEntity.spec.definition.replace(appSyncPattern, (match) => match.includes('{') ? '{' : '') : apiEntity.spec.definition;
          }
          // find the widget for the type of api entity
          return definitionWidgets.find(d => d.type === apiEntity.spec.type);
        },
      };
    },
  }),
  // SCM Gitlab Integration
  createApiFactory({
    api: scmIntegrationsApiRef,
    deps: { configApi: configApiRef },
    factory: ({ configApi }) => ScmIntegrationsApi.fromConfig(configApi),
  }),
  // Disable default factory for custom ScmAuth.createDefaultApiFactory(),
  // Gitlab P7S1
  createApiFactory({
    api: scmAuthApiRef,
    deps: {
      gitlabAuthApi: gitlabAuthApiRef,
    },
    factory: ({ gitlabAuthApi }) =>
      ScmAuth.forGitlab(gitlabAuthApi, { host: 'gitlab.p7s1.io' }),
  }),
  // Pia auth
  createApiFactory({
    api: piaAuthApiRef,
    deps: {
      discoveryApi: discoveryApiRef,
      oauthRequestApi: oauthRequestApiRef,
      configApi: configApiRef,
    },
    factory: ({ discoveryApi, oauthRequestApi, configApi }) =>
      OAuth2.create({
        discoveryApi,
        oauthRequestApi,
        provider: {
          id: 'pia',
          title: 'PIA',
          icon: () => null,
        },
        defaultScopes: ['openid', 'email', 'profile'],
        environment: configApi.getOptionalString('auth.environment'),
      }),
  }),
  createApiFactory(techRadarApiRef, new P7S1TechRadar()),
  createApiFactory({
    api: tipsConfigRef,
    deps: {},
    factory: () => {
      return {
        tips: [...systemModelTips, ...extraTips],
      };
    },
  }),
  createApiFactory({
    api: cicdStatisticsApiRef,
    deps: { gitlabAuthApi: gitlabAuthApiRef },
    factory({ gitlabAuthApi }) {
      return new CicdStatisticsApiGitlab(gitlabAuthApi);
    },
  }),
  createApiFactory({
    api: graphQlBrowseApiRef,
    deps: {},
    factory: ({ }) =>
      GraphQLEndpoints.from([
        // Use the .create function if all you need is a static URL and headers.
        GraphQLEndpoints.create({
          id: 'gateway-stage',
          title: 'Gateway Stage',
          url: 'https://api-stg.joyn.de/graphql',
          // Optional extra headers
          headers: {
            "Joyn-Platform": "web",
          },
        }),
        GraphQLEndpoints.create({
          id: 'mesh-stg',
          title: 'Mesh Stage',
          url: 'https://api-stg.joyn.de/graphql',
          // Optional extra headers
          headers: {
            "Joyn-Platform": "web",
            "aws-cf-cd-mesh": "1"
          },
        }),
        // {
        //   id: 'hooli-search',
        //   title: 'Hooli Search',
        //   // Custom fetch function, this one is equivalent to using GraphQLEndpoints.create()
        //   // with url set to https://internal.hooli.com/search
        //   fetcher: async (params: any) => {
        //     return fetch('https://internal.hooli.com/search', {
        //       method: 'POST',
        //       headers: { 'Content-Type': 'application/json' },
        //       body: JSON.stringify(params),
        //     }).then(res => res.json());
        //   },
        // }
      ]),
    }),
  createApiFactory({
    api: exploreApiRef,
    deps: { 
      fetchApi: fetchApiRef,
      piaApi: piaAuthApiRef, 
      discoveryApi: discoveryApiRef
    },
    factory: ({ fetchApi, piaApi, discoveryApi }) => {
      return {
        async getTools() {
          const backstageToken = await piaApi.getBackstageIdentity();
          const piaToken = await piaApi.getAccessToken();
          // replace user:default/pgarri with pgarri
          const userid = backstageToken?.identity.userEntityRef.replaceAll("user:default/", "");
          // https://backstage.p7s1.io/api/proxy/api-sre/my/pgarri/myapps
          // here we should do the filtering based on the pia permission as described here https://gitlab.p7s1.io/sre/discovery/lambda/-/blob/master/src/app.py
          const piaTools = await (await fetchApi.fetch(
            `${await discoveryApi.getBaseUrl('proxy')}/api-sre/my/${userid}/myapps`, // `https://api-sre.p7s1.io/prism/my/${userid}/myapps`,
            {
              mode: 'cors',
              credentials: 'include',
              headers: {
                'x-requested-with': 'XMLHttpRequest',
                Authorization: `Bearer ${piaToken}`,
              },
            },
          )).json();
          
          const authorizedTools = await Promise.all(piaTools.map(async (tool: { name: string; description: string; url: string; image_url: string; }) => {
            return {
              title: tool.name,
              description: tool.description,
              url: tool.url,
              image: tool.image_url,
              tags: [],
            };
          }));
          return {
            tools: authorizedTools,
          };
        },
      };
    },
  }),
];

