import {
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { KPI_ANNOTATION } from './components/useProjectKey';
import { Entity } from '@backstage/catalog-model';

export const soegPlugin = createPlugin({
  id: 'soeg',
  routes: {
    root: rootRouteRef,
  },
});

export const isSoegKpiAvailable = (entity: Entity) =>
  Boolean(entity?.metadata?.annotations?.[KPI_ANNOTATION]);

export const SoegKpiPage = soegPlugin.provide(
  createRoutableExtension({
    name: 'SoegKpiPage',
    component: () =>
      import('./components/SoegKpiPage').then(m => m.SoegKpiPage),
    mountPoint: rootRouteRef,
  }),
);

export const SoegEasyPermissionTable = soegPlugin.provide(
  createComponentExtension({
    name: 'SoegEasyPermissionTable',
    component: {
      lazy: () =>
        import('./components/SoegEasyPermissionTable').then(
          m => m.SoegEasyPermissionTable,
        ),
    },
  }),
);
